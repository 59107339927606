import Link from 'next/link';

export function Footer() {
  return (
    <div className="flex flex-col h-20 bg-white justify-center items-center space-y-2 px-2">
      <div className="flex flex-col sm:flex-row justify-center space-x-6">
        <Link href="/agbs"><span className="whitespace-nowrap">Allgemeine Geschäftsbedingungen</span></Link>
        <Link href="/datenschutz">Datenschutzerklärung</Link>
      </div>
      <div>&copy; 2023 MARKTKOST Lunch as a Service GmbH</div>
    </div>
  );
}
