import axios, { AxiosError } from 'axios';
import { getAccessToken } from '@/backend/auth/auth';

export type BackendError = AxiosError & { response: { data: { message: string } } };

const instance = axios.create({
  baseURL: 'http://localhost:3000',
});

export interface User {
  firstName: string;
  lastName: string;
}

export const fetchUser = async () => {
  const accessToken = await getAccessToken();
  const authHeader = accessToken ? `Bearer ${accessToken}` : undefined;

  return (await instance.get(
    `${process.env.NEXT_PUBLIC_SERVICES_URL}/user/me`,
    { headers: { Authorization: authHeader } },
  )
  ).data;
};

export interface WeekOrderItem {
  productId: string;
  userQuantity: number;
}

export interface WeekOrder {
  numberOfProducts: number;
  confirmedNumberOfMeals: number;
  status: string;
  items: WeekOrderItem[];
  bill: OrderBill;
  hasPaymentMethod: boolean;
}

export interface Subscription {
  numberOfMeals: number;
  status: string;
}

export interface WeekProduct {
  productId: string;
  title: string;
  description: string;
  properties: string[];
  imageUrl: string;
  cardUrl: string;
}

export interface Week {
  id: string;
  startDate: string;
  order?: WeekOrder;
  subscription: Subscription;
  products: WeekProduct[];
  isBlocked: boolean;

}

export interface InvoiceAddress {
  streetAndNumber?: string;
  additionalAddress?: string;
  zipCode?: string;
  city?: string;
}

export interface PersonalData {
  salutation: 'f' | 'm' | 'd' | 'n/a';
  firstName: string;
  lastName: string;
  email: string;
  invoiceAddress: InvoiceAddress;
}

export interface PaymentMethod {
  type: string;
  description: string;
}

export interface Settings {
  firstActiveWeek: string;
  subscription: {
    numberOfMeals: number;
    preference: string;
    status: string;
  },
  personalData: PersonalData;
  paymentMethod?: PaymentMethod;
}

instance.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    window.location.href = '/login';
  }
});

export const fetchWeeks = async () => {
  const accessToken = await getAccessToken();
  const authHeader = accessToken ? `Bearer ${accessToken}` : undefined;

  return (await instance.get(
    `${process.env.NEXT_PUBLIC_SERVICES_URL}/user/weeks`,
    { headers: { Authorization: authHeader } },
  )
  ).data;
};

export interface UpdateOrderDto {
  weekId: string;
  dto: { items: WeekOrderItem[]; source: 'user', confirmedNumberOfMeals: number };
}

export async function updateOrder({ weekId, dto }: UpdateOrderDto) {
  const accessToken = await getAccessToken();
  const authHeader = accessToken ? `Bearer ${accessToken}` : undefined;

  return (await axios.put(
    `${process.env.NEXT_PUBLIC_SERVICES_URL}/user/order/${weekId}`,
    dto,
    { headers: { Authorization: authHeader } },
  )).data;
}

export interface PauseOrderParams {
  weekId: string;
}

export async function pauseOrder({ weekId }: PauseOrderParams) {
  const accessToken = await getAccessToken();
  const authHeader = accessToken ? `Bearer ${accessToken}` : undefined;

  return (await axios.put(
    `${process.env.NEXT_PUBLIC_SERVICES_URL}/user/order/${weekId}/pause`,
    {},
    { headers: { Authorization: authHeader } },
  )).data;
}

export interface ResumeOrderParams {
  weekId: string;
}

export async function resumeOrder({ weekId }: ResumeOrderParams) {
  const accessToken = await getAccessToken();
  const authHeader = accessToken ? `Bearer ${accessToken}` : undefined;

  return (await axios.put(
    `${process.env.NEXT_PUBLIC_SERVICES_URL}/user/order/${weekId}/resume`,
    {},
    { headers: { Authorization: authHeader } },
  )).data;
}

export interface ResetPasswordDto {
  email: string;
}

export async function resetPassword(dto: ResetPasswordDto) {
  return (await axios.post(
    `${process.env.NEXT_PUBLIC_SERVICES_URL}/auth/user/trigger-reset-password`,
    dto,
  )).data;
}

export interface NewPasswordDto {
  token: string;
  password: string;
}

export async function newPassword(dto: NewPasswordDto) {
  return (await axios.post(
    `${process.env.NEXT_PUBLIC_SERVICES_URL}/auth/user/reset-password`,
    dto,
  )).data;
}

export const fetchSettings = async () => {
  const accessToken = await getAccessToken();
  const authHeader = accessToken ? `Bearer ${accessToken}` : undefined;

  return (await instance.get(
    `${process.env.NEXT_PUBLIC_SERVICES_URL}/user/settings`,
    { headers: { Authorization: authHeader } },
  )
  ).data as Settings;
};

export interface UpdateSubscriptionDto {
  startDate: string;
  numberOfMeals: number;
  preference: string;
}

export const updateSubscription = async (dto: UpdateSubscriptionDto) => {
  const accessToken = await getAccessToken();
  const authHeader = accessToken ? `Bearer ${accessToken}` : undefined;

  return (await instance.put(
    `${process.env.NEXT_PUBLIC_SERVICES_URL}/user/subscription`,
    dto,
    { headers: { Authorization: authHeader } },
  )
  ).data;
};

export const cancelSubscription = async () => {
  const accessToken = await getAccessToken();
  const authHeader = accessToken ? `Bearer ${accessToken}` : undefined;

  return (await instance.post(
    `${process.env.NEXT_PUBLIC_SERVICES_URL}/user/subscription/cancel`,
    {},
    { headers: { Authorization: authHeader } },
  )
  ).data;
};

export interface UpdatePersonalDataParams {
  firstName: string;
  lastName: string;
  email: string;
  streetAndNumber?: string;
  additionalAddress?: string;
  zipCode?: string;
  city?: string;
}

export const updatePersonalData = async (dto: UpdatePersonalDataParams) => {
  const accessToken = await getAccessToken();
  const authHeader = accessToken ? `Bearer ${accessToken}` : undefined;

  return (await instance.put(
    `${process.env.NEXT_PUBLIC_SERVICES_URL}/user/personal-data`,
    dto,
    { headers: { Authorization: authHeader } },
  )
  ).data;
};

export interface SubscriptionBill {
  startDate: string;
  numberOfMeals: number;
  discountPerMealCent: number;
  sponsoringPerMealCent: number;
  unlimitedSponsoring: boolean;
  maxSponsoringPerWeekCent?: number;
  isTrial: boolean;
  pricePerMealCent: number;
  mealPriceTotalCent: number;
  discountTotalCent: number;
  sponsoringTotalCent: number;
  totalCent: number;
}

export interface GetSubscriptionBillParams {
  startDate: string;
  numberOfMeals: number;
}

export const fetchSubscriptionBill = async (params: GetSubscriptionBillParams) => {
  const accessToken = await getAccessToken();
  const authHeader = accessToken ? `Bearer ${accessToken}` : undefined;

  return (await instance.get(
    `${process.env.NEXT_PUBLIC_SERVICES_URL}/user/subscription-bill`,
    { params, headers: { Authorization: authHeader } },
  )
  ).data as SubscriptionBill;
};

export interface TotalBill {
  numberOfMeals: number;
  mealPriceTotalCent: number;
  discountTotalCent: number;
  sponsoringTotalCent: number;
  totalCent: number;
}

export interface OrderBill {
  startDate: string;
  discountPerMealCent: number;
  sponsoringPerMealCent: number;
  unlimitedSponsoring: boolean;
  maxSponsoringPerWeekCent?: number;
  isTrial: boolean;
  isPayroll: boolean;
  pricePerMealCent: number;
  total: TotalBill;
  subscription: TotalBill;
  additional: TotalBill;
}

export interface FetchOrderBillParams {
  startDate: string;
  numberOfMeals: number;
}

export const fetchOrderBill = async (params: FetchOrderBillParams) => {
  const accessToken = await getAccessToken();
  const authHeader = accessToken ? `Bearer ${accessToken}` : undefined;

  return (await instance.get(
    `${process.env.NEXT_PUBLIC_SERVICES_URL}/user/order-bill`,
    { params, headers: { Authorization: authHeader } },
  )
  ).data as OrderBill;
};

export type ProductColor = 'red' | 'green' | 'yellow' | 'blue' | 'orange' | 'white' | 'unknown';
export type PaymentStatus = 'open' | 'pending' | 'processing' | 'not-necessary' | 'paid' | 'failed' | 'woo' | 'unknown';

export interface ClosedOrderItem {
  productId: string;
  productTitle: string;
  productImageUrl: string;
  productCardUrl: string;
  productColor: ProductColor;
  quantity: number;
}

export interface ClosedOrder {
  id: string;
  orderNumber?: string;
  isWoo: boolean;
  isActive: boolean;
  weekId: string;
  deliveryDate: string;
  totalCent?: number;
  paymentStatus?: PaymentStatus;
  hasInvoiceDoc: boolean;
  hasRefundDoc: boolean;
  claimAllowed: boolean;
  paymentMethodType?: string;
  items: ClosedOrderItem[];
}

export const fetchClosedOrders = async () => {
  const accessToken = await getAccessToken();
  const authHeader = accessToken ? `Bearer ${accessToken}` : undefined;

  return (await instance.get(
    `${process.env.NEXT_PUBLIC_SERVICES_URL}/user/closed-orders`,
    { headers: { Authorization: authHeader } },
  )
  ).data as ClosedOrder[];
};

export const downloadInvoicePdf = async (weekId: string) => {
  const accessToken = await getAccessToken();
  const authHeader = accessToken ? `Bearer ${accessToken}` : undefined;

  const response = (await instance.post(
    `${process.env.NEXT_PUBLIC_SERVICES_URL}/user/order/${weekId}/invoice`,
    {},
    { headers: { Authorization: authHeader }, responseType: 'blob' },
  )
  );

  return {
    blob: response.data,
    filename: response.headers['content-disposition']?.split('filename=')[1].replaceAll('"', '').trim()
        || 'rechnung.pdf',
  };
};

export const downloadRefundPdf = async (weekId: string) => {
  const accessToken = await getAccessToken();
  const authHeader = accessToken ? `Bearer ${accessToken}` : undefined;

  const response = (await instance.post(
    `${process.env.NEXT_PUBLIC_SERVICES_URL}/user/order/${weekId}/refund`,
    {},
    { headers: { Authorization: authHeader }, responseType: 'blob' },
  )
  );

  return {
    blob: response.data,
    filename: response.headers['content-disposition']?.split('filename=')[1].replaceAll('"', '').trim()
        || 'rechnung.pdf',
  };
};

export interface SavePaypalDto {
  nonce: string;
}

export const savePaypal = async (dto: SavePaypalDto) => {
  const accessToken = await getAccessToken();
  const authHeader = accessToken ? `Bearer ${accessToken}` : undefined;

  return (await instance.post(
    `${process.env.NEXT_PUBLIC_SERVICES_URL}/user/braintree/paypal`,
    dto,
    { headers: { Authorization: authHeader } },
  )
  ).data;
};

export interface BraintreeClient {
  paypalClientId: string;
  clientToken: string;
}

export const fetchBraintreeClient = async () => {
  const accessToken = await getAccessToken();
  const authHeader = accessToken ? `Bearer ${accessToken}` : undefined;

  return (await instance.get(
    `${process.env.NEXT_PUBLIC_SERVICES_URL}/user/braintree/client`,
    { headers: { Authorization: authHeader } },
  )
  ).data as BraintreeClient;
};

export interface StripeSetupIntent {
  clientSecret: string;
}

export interface CreateStripeSetupIntentDto {
  stripePaymentMethod: string;
}

export const fetchStripeSetupIntent = async (dto: CreateStripeSetupIntentDto) => {
  const accessToken = await getAccessToken();
  const authHeader = accessToken ? `Bearer ${accessToken}` : undefined;

  return (await instance.post(
    `${process.env.NEXT_PUBLIC_SERVICES_URL}/user/stripe/setup-intent`,
    dto,
    { headers: { Authorization: authHeader } },
  )
  ).data as StripeSetupIntent;
};

fetchStripeSetupIntent
  .key = (stripePaymentMethod: string) => ['stripe-setup-intent', stripePaymentMethod];

export interface SaveStripeDto {
  setupIntentId: string;
}

export const saveStripe = async (dto: SaveStripeDto) => {
  const accessToken = await getAccessToken();
  const authHeader = accessToken ? `Bearer ${accessToken}` : undefined;

  return (await instance.post(
    `${process.env.NEXT_PUBLIC_SERVICES_URL}/user/stripe/payment-methods`,
    dto,
    { headers: { Authorization: authHeader } },
  )
  ).data;
};

export interface WeekPreview {
  products: WeekProduct[],
  unit: string;
  weekHeader: string;
}

export const fetchWeekPreview = async (unitId: string) => (await instance.get(
  `${process.env.NEXT_PUBLIC_SERVICES_URL}/units/${unitId}/week-preview`,
)
).data;
